import React from 'react';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <div className={`py-5 select-none text-center flex-none space-y-5`}>
            <img className='mx-auto' src={require('../../assets/cequence.png')} alt="logo" style={{ height: 25 }} />
            <p className="text-gray-600">
                &copy;{year}&nbsp;
                <a href="/" title="Cequence">
                    Cequence
                </a>
                . All rights reserved.
            </p>
        </div>
    );
};

export default Footer;
